.feature-box-full {
  min-height: 899.11px;
  padding-top: 100px;
  box-sizing: border-box;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-box-full h1 {
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  margin-bottom: 10px;
}

.feature-box-full p {
  max-width: 858.9px;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  opacity: 0.8;
  margin-bottom: 50px;
}

.feature-box-full img {
  max-width: 800px;
  height: 500px;
  filter: drop-shadow(10px 15px 50px rgba(0, 0, 0, 0.08));
}
