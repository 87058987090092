.faq {
  min-height: 801px;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 80px;
  box-sizing: border-box;
}

.faq h1 {
  font-size: 40px;
  line-height: 51px;
  text-align: center;
  margin-bottom: 30px;
}

.faq-content {
  max-width: 960px;
  width: 960px;
}

.faq-list {
  width: 100%;
  background-color: transparent;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "EC";
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
}

.faq-list-active {
  background-color: white;
}

.faq-list p {
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
}

.faq-item {
  padding: 20px;
}

.faq-item p {
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
}

.faq-sub-title {
  font-size: 16px;
  font-weight: 600;
}

.faq-sub-content {
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
}
