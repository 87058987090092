.mp-navigation {
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.04);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.logo {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none;
}

.logo-tagline {
  font-size: 20px;
  line-height: 160%;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.mp-navigation .nav-links {
  font-size: 16px;
  line-height: 160%;
  opacity: 0.8;
  text-decoration: none;
  margin-right: 40px;
}

.mp-navigation .nav-links:last-child {
  margin-right: 0;
}

.nav-button {
  width: 130px;
  height: 40px;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
}

.nav-button:hover {
  background-color: #0f0627;
  transition: 0.2s ease-in-out;
}

.show-item-sm {
  display: none !important;
}

.nav-more {
  cursor: pointer;
}

.nav-drop-down {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1;
  padding: 0 20px;
  padding-top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.nav-drop-down .nav-button {
  margin: 0;
}

.nav-drop-down .nav-links {
  font-size: 20px;
}

.nav-drop-down a {
  margin-bottom: 40px;
  text-decoration: none;
}

@media only screen and (max-width: 1075px) {
  .mp-navigation {
    padding: 0 20px !important;
  }
  .nav-button {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .mp-nav {
    display: none !important;
  }
  .remove-item-sm {
    display: none !important;
  }
  .show-item-sm {
    display: flex !important;
  }
}
