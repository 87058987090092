.form-container {
  width: 100%;
  height: 100%;
  padding: 75px 0;
}

.form-container .content {
  max-width: 500px;
}

.form-container .title {
  font-size: 55px;
  margin-bottom: 10px;
}

.form-container .subtitle {
  max-width: 400px;
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 40px;
}

.form-container .label {
  margin-bottom: 10px;
}

@media only screen and (max-width: 870px) {
  .form-container .title {
    font-size: 40px;
  }
}
