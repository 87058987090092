.input-container {
  width: 100%;
  height: 50px;
  position: relative;
  margin-bottom: 10px;
}

.input-container input {
  width: 100%;
  height: 50px;
  background-color: #f8f8f9;
  border-radius: 12px;
  border: none;
  outline: none;
  padding: 0 20px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;
}

.right-icon {
  position: absolute;
  right: 25px;
  top: 18px;
  cursor: pointer;
}

.right-icon img {
  height: 20px;
}

.right-icon:hover {
  opacity: 0.5;
}

.right-icon .password {
  height: 10px !important;
}
