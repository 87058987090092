.mock-container {
  width: 503px;
  height: 502.01px;
  overflow: hidden;
  border: 1px solid #eaedf5;
  padding: 0 75.5px;
  box-sizing: border-box;
  position: relative;
}

.mock-container img {
  position: absolute;
  width: 352px;
  height: 709.59px;
  top: 69.64px;
  filter: drop-shadow(10px 15px 50px rgba(0, 0, 0, 0.17));
}

.mock-texts {
  flex-direction: column;
  flex: 1;
}

.mock-texts h1 {
  font-size: 48px;
  line-height: 105%;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
}

.mock-texts p {
  font-size: 16px;
  line-height: 160%;
  margin-bottom: 20px;
}

.mock-texts .check-list p {
  font-weight: 200;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0;
  margin-left: 8px;
}

.feature-box {
  min-height: 307px !important;
}
