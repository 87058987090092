.error-container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.error-container .text-content {
  width: 40%;
}

.error-container .illustration {
  flex: 1;
}

.error-container .text-content-v {
  width: 20%;
  margin-right: 40px;
}

.text-content h1 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 20px;
}

.text-content-v h1 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 20px;
  text-align: center;
}

.text-content .error-code {
  font-style: normal;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 20px;
}

.text-content .description {
  font-size: 15px;
  line-height: 25px;
  margin-bottom: 33px;
}

.text-content .button-container {
  width: 119px;
}

.text-content-v .button-container {
  width: 130px;
}

.illustration .illu-icon {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 790px) {
  .error-container {
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .error-container .text-content {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .error-container .illustration {
    display: none;
  }
  .error-container .text-content-v {
    width: 100%;
    margin: 0;
    margin-bottom: 40px;
  }
}
