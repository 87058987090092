.about-container {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 60px;
  padding-top: 50px;
}

.about-container p {
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
  color: #757886;
  margin-bottom: 40px;
}

.about-container h1 {
  font-size: 40px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 640px) {
  .about-container {
    margin-top: 100px;
  }

  .about-container p {
    font-size: 18px;
  }

  .about-container h1 {
    font-size: 30px;
  }
}
