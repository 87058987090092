.mp-footer {
  width: 100%;
  height: 189.08px;
}

.logo {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.mp-foot-nav p {
  color: white;
  margin-right: 10px;
}

.mp-foot-nav p:last-child {
  margin-right: 0;
}

.mp-foot-nav a {
  text-decoration: underline;
  color: #ff006c !important;
}

.mp-foot-nav a:hover {
  opacity: 0.5;
}

.mp-footer .nav-links {
  font-size: 16px;
  line-height: 160%;
  color: #ffffff;
  text-decoration: none;
  margin-right: 40px;
}

.mp-footer .nav-links:last-child {
  margin-right: 0;
}

.mp-footer .nav-links-active {
  color: #f8f8f9;
  opacity: 0.8;
}
