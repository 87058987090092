@font-face {
  font-family: "EC";
  src: url("../assets/fonts/EuclidCircularA-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "EC";
  src: url("../assets/fonts/EuclidCircularA-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "EC";
  src: url("../assets/fonts/EuclidCircularA-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "EC";
  src: url("../assets/fonts/EuclidCircularA-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "ECMedium";
  src: url("../assets/fonts/EuclidCircularA-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

p,
input,
textarea,
select,
button,
span,
a,
table,
label,
li {
  font-family: EC, sans-serif;
  font-family: 400;
}

h1 {
  font-family: EC, sans-serif;
  font-family: 700;
}

p,
h1 {
  margin: 0;
}
