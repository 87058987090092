body {
  margin: 0;
}

.text-primary {
  color: #0f0627;
}

.text-secondary {
  color: #757886;
}

.text-black-01 {
  color: #0c2344;
}

.bg-primary {
  background-color: #ff006c;
}

.bg-light-blue {
  background-color: #f5f9ff;
}

.bg-light-orange {
  background-color: #fff7f5;
}

.bg-light-green {
  background-color: #f2fefd;
}

.bg-dark {
  background-color: #0f0627;
}

.br-24 {
  border-radius: 24px;
}

.br-32 {
  border-radius: 32px;
}

.br-60 {
  border-radius: 60px;
}

.br-64 {
  border-radius: 64px;
}

.br-12 {
  border-radius: 12px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-99 {
  margin-right: 99px;
}

.ml-99 {
  margin-left: 99px;
}

.pd-space-left {
  padding-left: 170px;
  box-sizing: border-box;
}

.pd-space-horizontal {
  padding-left: 170px;
  padding-right: 170px;
  box-sizing: border-box;
}

.pd-space-vertical {
  padding-top: 80px;
  padding-bottom: 80px;
  box-sizing: border-box;
}

.pd-space-md-horizontal {
  padding-left: 70px;
  padding-right: 70px;
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.space-center {
  justify-content: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

.main-container {
  margin-top: 80px;
}

.hero-section {
  width: 100%;
  height: 570px;
  background: url("../assets/images/png/hero-employer.png");
  background-position: 920px 120px;
  background-size: 800px;
  background-repeat: no-repeat;
}

.hero-section-talents {
  background: url("../assets/images/png/hero-talent.png");
  background-position: 920px 100px;
  background-size: 420px;
  background-repeat: no-repeat;
  margin-bottom: 186px;
}

.hero-title {
  max-width: 691px;
  font-size: 72px;
  margin-bottom: 10px;
}

.hero-subtitle {
  font-size: 24px;
  margin-bottom: 36px;
  opacity: 0.8;
  max-width: 541px;
}

.hero-button {
  width: 200px;
  height: 50px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}

.hero-store img {
  margin-right: 10px;
  cursor: pointer;
}

.hero-store img:hover {
  opacity: 0.9;
}

.hero-button:hover {
  background-color: #0f0627;
  transition: 0.2s ease-in-out;
}

.feature-section h1 {
  width: 100%;
  text-align: center;
  font-size: 72px;
  line-height: 67px;
  margin-bottom: 40px;
}

.feature-box {
  width: 32%;
  min-height: 442px;
  padding: 40px 29px;
  box-sizing: border-box;
}

.feature-employer {
  margin-bottom: 10px;
}

.feature-box h1 {
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 10px;
  text-align: left;
}

.feature-box p {
  font-size: 18px;
  line-height: 160%;
  opacity: 0.8;
}

.footer-hero {
  min-height: 453.85px;
  flex-direction: column;
  margin-bottom: 80px;
  background-image: url("../assets/images/png/pattern.png");
  background-size: cover;
  background-position: bottom;
}

.footer-hero h1 {
  font-size: 67px;
  line-height: 85px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 20px;
}

.footer-hero p {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  max-width: 648.05px;
  letter-spacing: 0.01em;
  color: #f8f8f9;
  margin-bottom: 20px;
}

.footer-hero-talents p {
  max-width: 752.27px !important;
}

.footer-hero button {
  width: 200px;
  height: 50px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}

.footer-hero button:hover {
  background-color: #0f0627;
  transition: 0.2s ease-in-out;
}

@media only screen and (max-width: 1150px) {
  .pd-space-horizontal {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pd-space-left {
    padding: 0 20px;
  }
  .hero-section-talents {
    background-position: 650px 100px;
    background-size: 420px;
    margin-bottom: 40px;
  }
  .hero-section {
    width: 100%;
    height: 500px;
    background: none;
  }
  .pd-space-left {
    padding: 0 40px;
  }
  .hero-title {
    font-size: 45px;
  }
  .hero-subtitle {
    font-size: 16px;
  }
  .feature-box-full {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
  }
  .feature-box-full h1,
  .mock-texts h1,
  .feature-section h1 {
    font-size: 30px;
    line-height: 32px;
  }
  .feature-box-full p,
  .mock-texts p {
    font-size: 18px;
    line-height: 28px;
  }
  .feature-box-full img,
  .mock-container img {
    width: 100%;
    height: auto;
  }
  .footer-hero h1 {
    font-size: 40px;
    line-height: 40px;
  }
  .footer-hero p {
    font-size: 20px;
  }
  .footer-hero {
    padding: 0 20px;
  }
  .pd-space-md-horizontal {
    padding: 0 20px;
  }
  .faq-content {
    width: 100%;
  }
  .faq-content p {
    font-size: 14px;
    margin-right: 10px;
  }
  .faq-content img {
    width: 14px;
    height: 14px;
  }
  .tal {
    flex-wrap: wrap;
  }
  .tal .mock-container {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  .tal .mock-texts {
    width: 100%;
  }
  .tal-feature {
    flex-wrap: wrap;
  }
  .feature-box {
    width: 100%;
    margin-bottom: 15px;
  }
  .tal .mock-container {
    height: 300px;
  }
  .tal .mock-container img {
    width: 30%;
    height: auto;
  }
}

@media only screen and (max-width: 870px) {
  .feature-section {
    flex-wrap: wrap;
  }
  .hero-store {
    flex-wrap: wrap;
  }
  .hero-store img {
    margin-bottom: 10px;
  }
  .footer-hero-talents .hero-store {
    justify-content: center;
  }
  .footer-hero {
    min-height: 550px;
  }
  .tal .mock-container img {
    width: 40%;
    height: auto;
  }
}

@media only screen and (max-width: 500px) {
  .tal .mock-container img {
    width: 60%;
    height: auto;
  }
  .footer-hero {
    min-height: 400px;
    padding: 20px;
  }
  .footer-hero h1 {
    font-size: 25px;
    margin-bottom: 5px;
  }
  .footer-hero p {
    font-size: 15px;
  }
  .mp-footer {
    flex-wrap: wrap;
  }
  .mp-footer .logo-container {
    width: 100%;
    align-items: flex-start;
  }
  .mp-footer .mp-foot-nav {
    width: 100%;
    flex-wrap: wrap;
  }
  .mp-footer .mp-foot-nav p {
    margin-bottom: 10px;
  }
  .mp-footer .nav_links {
    width: 100%;
  }
}
